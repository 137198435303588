import { Loading } from "@common/components";
import { lazyLoad } from "@common/utils";

export const ContractorsPageAsync = lazyLoad(
  () => import("./index"),
  module => module.ContractorsPage,
  {
    fallback: <Loading />,
  },
)
