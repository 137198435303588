import React, { ReactElement } from "react";
import { AccountsPageAsync } from "@account/AccountsPageAsync";
import {
  ArticlesPage,
  ChooseBudgetPage,
  ContractorsPageAsync,
  ManagersRequestsPage,
  NotFoundPage,
  NotificationsPage,
  SettingsPage,
  SubscriptionPage,
  UnitUsersAsync,
} from "@common/pages";
import { BudgetPageAsync } from "@budget/BudgetPageAsync";
import { UnitAnalyticsAsync } from "../../unit/analytics/UnitAnaliticsAsync";
import { AccountsDetailPageAsync } from "@account-detail/AccountsDetailPageAsync";
import { ChangePassword, Login, RedirectUpdatePassword, Signup, UpdatePassword } from "@auth/view";
import { AuthPageWrapper } from "@auth/index";

export type RouteType = {
  path: string;
  auth?: boolean;
  roleGuard?: boolean;
  element: ReactElement;
};
export const ROUTERS: Array<RouteType> = [
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/accounts",
    element: (
      <AccountsPageAsync />
    ),
    auth: true,
  },
  {
    path: "/accounts/:id",
    element: <AccountsDetailPageAsync />,
    auth: true,
  },
  {
    path: "/analytics",
    element: <UnitAnalyticsAsync />,
    auth: true,
  },
  {
    path: "/managers_requests",
    element: <ManagersRequestsPage />,
    auth: true,
  },
  {
    path: "/notifications",
    element: <NotificationsPage />,
    auth: true,
  },
  {
    path: "/users",
    element: <UnitUsersAsync />,
    auth: true,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
    auth: true,
  },
  {
    path: "/subscription",
    element: <SubscriptionPage />,
  },
  {
    path: "/budgets",
    element: <ChooseBudgetPage />,
    auth: true,
  },
  {
    path: "/articles",
    element: <ArticlesPage />,
    auth: true,
  },
  {
    path: "/auth/login",
    element: (
      <AuthPageWrapper>
        <Login />
      </AuthPageWrapper>
    ),
  },
  {
    path: "/auth/signup",
    element: (
      <AuthPageWrapper>
        <Signup />
      </AuthPageWrapper>
    ),
  },
  {
    path: "/auth/change-password",
    element: (
      <AuthPageWrapper>
        <ChangePassword />
      </AuthPageWrapper>
    ),
  },
  {
    path: "/auth/update_password",
    element: (
      <AuthPageWrapper>
        <UpdatePassword />
      </AuthPageWrapper>
    ),
  },
  {
    path: "/auth/redirect_update_password/:token",
    element: (
      <AuthPageWrapper>
        <RedirectUpdatePassword />
      </AuthPageWrapper>
    ),
  },
  {
    path: "/contractors",
    element: <ContractorsPageAsync />,
    auth: true,
  },
  {
    path: "/",
    element: <BudgetPageAsync />,
    roleGuard: true,
  },
];
