import { GlobalPopup, LayoutWrapper, PortalContainer } from "@common/components";
import { persistedRootReducer } from "@common/store";
import { GlobalStyle, theme } from "@main/styles";
import { configureStore } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { ReactElement, StrictMode } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { PersistGate } from "reduxjs-toolkit-persist/es/integration/react";
import { ThemeProvider } from "styled-components";
import { enableMapSet } from "immer";

import { Routing } from "./providers/routing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import("dayjs/locale/ru");
dayjs.locale("ru");

const logger: any = createLogger({
  level: "info",
  collapsed: true,
  duration: true,
  logErrors: true,
});

export const store: any = configureStore({
  reducer: persistedRootReducer,
  middleware: gDM => gDM().concat(logger, thunk),
  devTools: true,
});

const persist = persistStore(store);
const queryClient = new QueryClient();
enableMapSet();

export function App(): ReactElement {

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persist}>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <GlobalStyle />
                <GlobalPopup />
                <PortalContainer />
                <LayoutWrapper>
                  <Routing />
                </LayoutWrapper>
              </BrowserRouter>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </StrictMode>
  );
}
